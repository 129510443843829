import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import React, { useEffect } from "react"

import { Helmet } from "react-helmet"
import Footer from "../components/footer"
import HomeContact from "../components/HomePage/contact"
import HomeFeatures from "../components/HomePage/features"
import HomeHero from "../components/HomePage/hero"
import HomeKsero from "../components/HomePage/ksero"
import HomeWynajem from "../components/HomePage/wynajem"
import HomeZasady from "../components/HomePage/zasady"
import Layout from "../components/layout"
import Nav from "../components/nav"

gsap.registerPlugin(ScrollTrigger)
gsap.core.globals("ScrollTrigger", ScrollTrigger)

function IndexPage() {
  useEffect(() => {
    setTimeout(() => {
      gsap.to("body", 0, { css: { visibility: "visible" } })
      gsap.from(".wynajem-h2-anim", 0.7, {
        y: "-10%",
        opacity: 0,
        ease: "Back.easeInOut",
        scrollTrigger: {
          trigger: ".wynajem-right-trigger",
          start: "top 75%",
          toggleActions: "play none none reverse",
        },
      })
      gsap.from(".wynajem-text-anim", 1, {
        y: "20%",
        opacity: 0,
        ease: "Power4.easeInOut",
        delay: 0.2,
        scrollTrigger: {
          trigger: ".wynajem-right-trigger",
          start: "top 75%",
          toggleActions: "play none none reverse",
        },
      })
      gsap.from(".wynajem-button-anim", 0.8, {
        y: "30%",
        opacity: 0,
        ease: "Power4.easeInOut",
        delay: 0.4,
        scrollTrigger: {
          trigger: ".wynajem-right-trigger",
          start: "top 75%",
          toggleActions: "play none none reverse",
        },
      })
      gsap.fromTo(
        ".wynajem-left-anim",
        1.5,
        {
          x: "-100%",
          ease: "Expo.easeInOut",
        },
        {
          x: "200%",
          ease: "Expo.easeInOut",
          scrollTrigger: {
            trigger: ".wynajem-left-anim-trigger",
            start: "top center",
            toggleActions: "play none none reverse",
          },
        }
      )
    }, 1000)
  }, [])

  return (
    <Layout>
      <Helmet
        title="Wynajem i serwis kserokopiarek - Poznań | PM TONER"
        meta={[
          {
            name: `description`,
            content: `Zadbaj o płynne funkcjonowanie biura z PM TONER – liderem w wynajmie i serwisie kserokopiarek w Poznaniu. Oferujemy kompleksowe rozwiązania dla firm: wynajem nowoczesnych urządzeń, fachowy serwis i konkurencyjne ceny. Optymalizuj koszty i zainwestuj w efektywność dzięki naszym usługom. Skontaktuj się z nami już dziś!`,
          },
        ]}
      />
      <Nav />
      <HomeHero />
      <HomeWynajem />
      <HomeFeatures />
      <HomeZasady />
      <HomeKsero />
      <HomeContact />
      <Footer />
    </Layout>
  )
}

export default IndexPage
